@import '../mixins.scss';
@import '../variables.scss';

[class^='app book-expert'] {
  .section-book-expert-hero {
    .section-content {
      @include section-content;
      padding-top: 40px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;

      @media all and (min-width: $break-point-sm) {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      .left-content {
        width: 100%;

        p {
          margin-bottom: 20px;
        }

        @media all and (min-width: $break-point-tablet) {
          width: 50%;
        }
      }

      .right-content {
        display: none;
        width: 0;
        height: 0;
        background-image: url(../../../public/images/book_expert.svg);
        background-size: cover;
        background-repeat: no-repeat;

        @media all and (min-width: $break-point-tablet) {
          display: block;
          width: 50%;
          height: 608px;
        }
      }
    }
  }

  .section-expert-form {
    .section-content {
      @include section-content;
      padding-top: 40px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media all and (min-width: $break-point-sm) {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      .submit-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 42px;
      }

      p.form-footer {
        font-weight: $fontWeightBold;
        text-align: center;
        margin-bottom: 70px;

        a {
          color: $primaryColor;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .powered-by {
        display: flex;
        justify-content: center;

        span {
          color: $thirdTextColor;
          font-weight: $fontWeightSemibold;
          margin-right: 21px;
        }

        .lynk-logo {
          width: 81px;
          height: 28px;
          background-image: url(../../../public/images/lynk_gray.svg);
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

@import '../mixins.scss';
@import '../variables.scss';

@mixin left-links-big {
  .footer-logo {
    margin-right: 48px;
  }

  .social-link {
    &:not(:last-child) {
      margin-right: 27px;
    }
  }
}

@mixin left-links-small {
  .footer-logo {
    margin-right: 20px;
  }

  .social-link {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

@mixin right-links-big {
  .footer-link {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

@mixin right-links-small {
  .footer-link {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.section-footer {
  width: 100%;
  background-color: #f0f0f0;
  padding-top: 20px;
  padding-bottom: 20px;

  .section-content {
    @include section-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .footer-link {
      font-size: $fontSizeSmall;
      font-weight: 700;
      color: $mainTextColor;

      &:hover {
        color: $primaryColor;

        & > svg > path {
          fill: $primaryColor;
        }
      }
    }

    .left-content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      @include left-links-big();

      @media all and (min-width: $break-point-xs) {
        justify-content: flex-start;
        width: 40%;
        margin-bottom: 0;
        @include left-links-small();
      }

      @media all and (min-width: $break-point-md) {
        width: 25%;
        @include left-links-small();
      }

      @media all and (min-width: $break-point-lg) {
        width: 33%;
        @include left-links-big();
      }
    }

    .center-content {
      width: 100%;
      flex: unset;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      font-size: $fontSizeSmall;

      @media all and (min-width: $break-point-xs) {
        width: unset;
        flex: 1;
        justify-content: flex-end;
        margin-bottom: 0;
      }

      @media all and (min-width: $break-point-md) {
        justify-content: center;
      }
    }

    .right-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .footer-link {
        margin-bottom: 5px;
      }

      @media all and (min-width: $break-point-xxs) {
        flex-direction: row;
        justify-content: center;
        @include right-links-small();

        .footer-link {
          margin-bottom: 0;
        }
      }

      @media all and (min-width: $break-point-xs) {
        @include right-links-big();
      }

      @media all and (min-width: $break-point-md) {
        width: 35%;
        justify-content: flex-end;
        @include right-links-small();
      }

      @media all and (min-width: $break-point-lg) {
        width: 33%;
        @include right-links-big();
      }
    }

    @media all and (min-width: $break-point-md) {
      justify-content: space-between;
    }
  }

  @media all and (min-width: $break-point-xs) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  @media all and (min-width: $break-point-sm) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section-footer-start {
  width: 100%;
  background-color: #f8f8f8;

  .section-content {
    @include section-content;
    display: flex;
    justify-content: flex-start;

    .left-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 35px;
      padding-bottom: 35px;

      .content-header {
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 20px;
        text-align: center;

        @media all and (min-width: $break-point-xs) {
          text-align: left;
        }
      }

      @media all and (min-width: $break-point-sm) {
        width: 70%;
        align-items: flex-start;
      }

      @media all and (min-width: $break-point-lg) {
        width: 50%;
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }
  }

  @media all and (min-width: $break-point-sm) {
    background-image: url(../../../public/images/about/about-img-4.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right 0;
  }
}

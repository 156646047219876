// Media Settings
$break-point-xxl: 1680px;
$break-point-xl: 1400px;
$break-point-lg: 1200px;
$break-point-md: 1024px;
$break-point-sm: 996px;
$break-point-tablet: 768px;
$break-point-xs: 640px;
$break-point-xxs: 420px;

/* Colors */
$primaryColor: #f26e26;
$secondaryColor: #3c3634;
// $mainTextColor: #333333;
$mainTextColor: #444443;
$secondaryTextColor: #444443;
$thirdTextColor: #999999;
$color1: #595959;
$color2: #8c8c8c;
$color3: #83cfcb;
$color4: #e6f7ff;
$color5: #0097d9;
$color6: #007dba;
$color7: #de1269;
$outlineColor: #cccccc;
$highlightColor: #ffeeea;
$successColor: #74ae3b;
$errorColor: #e41407;
$mainBackgroundColor: #ffffff;
$lightBackgroundColor: #eeeeee;
$white: #ffffff;
$black: #000000;
$dividerDark: #dddddd;

$colorMainStep: 4%;

/* Shadows */
$shadowContainer: 0px 10px 30px rgba($black, 0.05);

/* Rounds */
$radiusMain: 8px;
$radiusBig: 20px;
$radiusComplete: 50%;

/* Typography */
$fontSizeTitle: 64px;
$fontSizeHeaderLarge: 48px;
$fontSizeHeader: 36px;
$fontSizeLead: 20px;
$fontSizeMain: 16px;
$fontSizeSmall: 14px;
$fontSizeCaption: 12px;

$fontWeightRegular: 400;
$fontWeightStrong: 500;
$fontWeightSemibold: 600;
$fontWeightBold: 700;

/* Transitions */
$transitionMain: linear 160ms;

/* Spacings */
$spaceSmallest: 4px;
$spaceSmall: 8px;
$spaceMedium: 14px;
$spaceMain: 20px;
$spaceHorizontal: 30px;

/* Width */
$contentWidth: 1106px;

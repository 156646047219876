@import '../mixins';
@import '../variables';

.chatListView {
  .chatListContinaer {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: calc(100vh - 145px);
    margin: 0;
    background-color: #ffffff;
    box-shadow: 0px 5px 50px rgba(0, 0, 0, 0.1);
    border: 1px solid #eeeeee;
    border-radius: 10px;
    .chatListSection {
      width: 340px;
      height: 100%;
    }
    .chatContentSection {
      flex: 1 0;
      border-left: 1px solid #dddddd;
      width: 100%;
      .noChats {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .userImage {
          text-align: center;
          margin: 27px;
          padding: 0;
          width: 80px;
          height: 80px;
          background-color: #fff;
          border-radius: 15px;
          overflow: hidden;
          box-shadow: inset 0 -1px 0 0 #badedc, 0px 5px 50px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: flex-end;
          justify-content: center;
          .imageContent {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          svg {
            fill: $outlineColor;
            margin-bottom: -7px;
          }
        }
        .welcome {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }
        p {
          color: $color2;
        }
      }
    }
  }
  .searchBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 83px;
    padding: 17px 18px;
    border-bottom: 1px solid #dddddd;
    flex-shrink: 0;
    .searchIcon {
      color: #cccccc;
      position: absolute;
      top: 29px;
      left: 32px;
    }
    input {
      width: 100%;
      border: none;
      flex: 1 0;
      font-size: 15px;
      color: #333333;
      padding: 8px 25px 8px 45px;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 30px;
    }
    span {
      font-family: Tiempo;
      font-weight: bold;
      font-size: 30px;
      line-height: 100%;
      color: #666666;
    }

    .selectInput {
      width: 70%;
      display: block;
      .reactSelect {
        width: 100% !important;
        display: block;
        div {
          background-color: #f5f5f5;
          border-radius: 10px;
        }
        span {
          background-color: #f5f5f5;
        }
        .reactSelect__option--is-selected {
          color: #ffffff;
          background-color: $primaryColor;
          div {
            background-color: $primaryColor;
          }
        }
        .reactSelect__control {
          border: none;
        }
      }
    }
  }
  .messageLimit {
    padding: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    .upgradePlan {
      font-weight: 600;
      color: $primaryColor;
      cursor: pointer;
    }
  }
  .chatList {
    overflow-y: auto;
    height: calc(100% - 80px);
    &.empty {
      background-repeat: no-repeat;
      background-position: center 20%;
      background-size: 80%;
      position: relative;
      .emptyMessage {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        position: absolute;
        top: 50%;
        margin: 40px 30px;
      }
    }
    .emptyMessage {
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      position: absolute;
      top: 50%;
      margin: 40px 30px;
    }
    .chatItem {
      padding: 20px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      position: relative;
      &.selected {
        background-color: #f5f5f5;
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          width: 4px;
          background-color: #f26e26;
        }
      }
      .messageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .lastMessageTime {
        text-align: right;
        font-size: 10px;
        line-height: 30px;
        color: #aaaaaa;
      }
      .chatImage {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 13px;
        position: relative;
        .unreadCount {
          position: absolute;
          right: 0;
          height: 20px;
          width: 20px;
          background-color: #ed402b;
          border: 1px solid #ffffff;
          border-radius: 60px;
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 120%;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ffffff;
          justify-content: center;
        }
      }
      .chatInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - 75px);
        .chatTitle {
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 250%;
        }
        .subscribed {
          margin-left: 10px;
          height: 13px;
          width: 52px;
          background-image: url(../../../public/images/home/pro.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
        .chatInfoPartner {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        .chatMessage {
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: #666666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.chatListView.compact {
  top: 0;
  left: 110px;
  right: 0;
  bottom: 0;
  padding: 0 !important;
  @media all and (max-width: 768px) {
    top: 88px;
    left: 0;
  }
  .pageTitle {
    display: none;
  }
  .chatListContinaer {
    height: 100%;
    border-radius: 0px;
    border: none;
    .chatListSection {
      width: 100%;
    }
    .chatContentSection {
      display: none;
    }
  }
  &.chatSelected {
    .chatListContinaer {
      .chatListSection {
        display: none;
      }
      .chatContentSection {
        display: block;
      }
    }
  }
}

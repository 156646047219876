@import '../../base';

[class^='app market-maps'] {
  // background-image: url(../../../../public/images/browse/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;

  @media all and (max-width: $break-point-sm) {
    background-image: none;
  }

  .appContent {
    background-color: transparent;

    .marketMapView {
      @include section-content;
      margin-top: 15px;
      margin-bottom: 50px;

      @media all and (min-width: $break-point-sm) {
        margin-top: 30px;
        margin-bottom: 100px;
      }

      .header.market-map-header {
        line-height: 44px;
        color: #444443;
        margin-bottom: 12px;
        font-size: 48px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;

        @media all and (max-width: $break-point-sm) {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 5px;
          color: $secondaryTextColor;
        }
      }

      .subheader {
        font-size: 24px;
        line-height: 30px;
        color: $secondaryTextColor;
        margin-bottom: 20px;
      }

      section {
        background: #fff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        padding: 15px;
        margin-bottom: $spaceMain;
        min-height: 200px;

        @media all and (min-width: 768px) {
          box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
          padding: 25px 35px;
          min-height: 300px;
        }
      }

      .sdg-tabbed {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eeeeee;

        .sdg-tab-list {
          display: flex;
          justify-content: space-evenly;
          width: 100%;

          @media all and (min-width: 768px) {
            justify-content: flex-start;
          }

          .sdg-tab {
            font-size: 16px;
            color: #999;
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0;
            cursor: pointer;

            @media all and (min-width: 768px) {
              font-size: 24px;
              padding-top: 26px;
              padding-bottom: 26px;
              margin-left: 50px;
              margin-right: 50px;
            }

            &.active {
              font-weight: 600;
              color: #f26e26;
              border-bottom: 3px solid #f26e26;
            }

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .sdg-tab-share {
        align-items: center;

        @media all and (min-width: 768px) {
          display: flex;
        }

        .sdg-share-icon {
          margin-right: 15px;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30px;
          width: 100px;
          border: 1px solid #999;
          border-radius: 50px;
          font-size: 14px;
          color: #999;
          line-height: 36px;
          text-transform: uppercase;
          background-color: #fff;

          @media all and (min-width: 768px) {
            height: 40px;
            width: 164px;
          }
        }
      }
    }
  }
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/* Custom properties */
:root {
  --tooltip-text-color: #666666;
  --tooltip-background-color: #ffffff;
  --tooltip-margin: 30px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
  border: 1px solid var(--tooltip-text-color);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

@import '../../mixins.scss';
@import '../../_variables.scss';

.flex-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Create four equal columns */
  .flex-column {
    font-size: 14px;
    flex: 15% 1;
    padding: 6px;
    font-weight: 600;
    text-align: center;
  }
  .flex-column-2 {
    flex: 75%;
    padding: 5px;
  }
  .flex-column-3 {
    flex: 10%;
    padding: 6px 6px 6px 40px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }

.searchInput {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 17px;
  height: 37px;
  display: flex;
  border: 1px solid #999999;
  flex-direction: row;
  align-items: flex-end;
  &::before {
    content: '';
    width: 17px;
    height: 17px;
    margin-right: 13px;
    background-image: url(../../../../public/images/icons/search.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &.shadowed {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  }
  &.bordered {
    border: 1px solid #ccc;
  }
  &.transparent {
    background-color: rgba(255, 255, 255, 0.2);
    input {
      color: #aaaaaa;
    }
  }
  input {
    appearance: none;
    border: none;
    background-color: transparent;
    border-radius: 0px;
    width: 100%;
    color: #333333;
    font-size: 14px;
    line-height: 18px;
    box-shadow: none;
    &::placeholder {
      color: #aaaaaa;
    }
  }
}

@import './_variables.scss';

@mixin fix-top($value) {
  top: $value; // fallback
  top: calc(env(safe-area-inset-top, 0px) + #{$value});
}

@mixin fix-bottom($value) {
  bottom: $value; // fallback
  bottom: calc(env(safe-area-inset-bottom, 0px) + #{$value});
}

@mixin fix-right($value) {
  right: $value; // fallback
  right: calc(env(safe-area-inset-right, 0px) + #{$value});
}

@mixin fix-left($value) {
  left: $value; // fallback
  left: calc(env(safe-area-inset-left, 0px) + #{$value});
}

@mixin overlay($zIndex: 9995) {
  position: fixed;
  @include fix-top(0px);
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: $zIndex;
}

@mixin fixed-header {
  position: fixed;
  @include fix-top(0px);
  width: 100%;
  z-index: 100;
}

@mixin fixed-footer {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 100;
  height: 60px;
  height: calc(env(safe-area-inset-bottom, 0px) + 60px);
  padding-bottom: env(safe-area-inset-bottom, 0px);
}

@mixin fixed-bottom {
  position: fixed;
  @include fix-bottom(0px);
}

@mixin absolute-cover {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

@mixin section-content {
  max-width: $contentWidth;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;

  @media all and (min-width: $break-point-lg) {
    padding-left: 0;
    padding-right: 0;
  }
}

@import '../mixins.scss';
@import '../variables.scss';

[class^='app home'] {
  .section-welcome {
    width: 100%;
    background-image: url(../../../public/images/home/welcome_bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

    .section-content {
      @include section-content;
      padding-top: 75px;
      padding-bottom: 75px;

      .content-header-lg {
        margin-bottom: 10px;
        text-align: center;

        @media all and (min-width: $break-point-sm) {
          text-align: left;
        }
      }

      .left-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .eco-business {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 25px;

          & > *:not(:last-child) {
            margin-right: 10px;
          }

          .eco-image {
            height: 26px;
            width: 104px;
            background-image: url(../../../public/images/home/eco-business.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
          }
        }

        p {
          margin-bottom: 20px;
          line-height: 175%;
          text-align: center;

          @media all and (min-width: $break-point-sm) {
            text-align: left;
          }
        }

        @media all and (min-width: $break-point-sm) {
          width: 70%;
          align-items: flex-start;
        }

        @media all and (min-width: $break-point-lg) {
          width: 40%;
        }
      }

      @media all and (min-width: $break-point-sm) {
        padding-top: 150px;
        padding-bottom: 150px;
      }
    }

    @media all and (min-width: $break-point-tablet) {
      background-position: right bottom;
    }
  }

  .section-goals {
    width: 100%;
    padding-top: 70px;
    padding-bottom: 70px;

    .section-content {
      @include section-content;

      .section-content__header {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        .left-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;

          .content-header {
            margin-bottom: 10px;
            text-align: center;

            @media all and (min-width: $break-point-sm) {
              text-align: left;
            }
          }

          p {
            margin-bottom: 20px;
            line-height: 175%;
            text-align: center;

            @media all and (min-width: $break-point-sm) {
              text-align: left;
            }
          }
        }

        .right-content {
          width: 196px;
          height: 163px;
          background-image: url(../../../public/images/home/SustainableDevelopmentGoalsLogo.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          margin-left: 0;
          margin-bottom: 20px;

          @media all and (min-width: $break-point-sm) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 60px;
            margin-bottom: 0;
          }
        }

        @media all and (min-width: $break-point-sm) {
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }

      a.button.button-dark-gray {
        margin-bottom: 20px;

        & > svg {
          margin-left: 10px;
        }

        @media all and (min-width: $break-point-sm) {
          margin-bottom: 40px;
        }
      }

      .section-content__goals {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: 20px;

        .goal-box {
          width: 173px;
          height: 195px;
          display: flex;
          flex-direction: column;
          color: $white;
          padding: 12px 15px 18px 15px;

          .goal-box__id {
            font-size: 24px;
            font-weight: bold;
          }

          .goal-box__svg-container {
            width: 121px;
            height: 118px;
            margin: 0 auto;
            margin-top: -19px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .goal-box__name {
            height: 43px;
            font-size: $fontSizeSmall;
            font-weight: $fontWeightBold;
            text-transform: uppercase;
            line-height: 125%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }

        @media all and (min-width: $break-point-xs) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media all and (min-width: $break-point-tablet) {
          margin-bottom: 40px;
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @media all and (min-width: $contentWidth) {
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        @media all and (min-width: $break-point-lg) {
          grid-template-columns: repeat(6, minmax(0, 1fr));
        }
      }
    }

    @media all and (min-width: $break-point-lg) {
      padding-top: 140px;
      padding-bottom: 140px;
    }
  }

  .section-impacts {
    width: 100%;
    background: linear-gradient(180deg, #eeeeee -82.52%, #ffffff 75.98%);
    padding-top: 50px;
    padding-bottom: 50px;

    .section-content {
      @include section-content;
      display: flex;
      flex-direction: column;

      .left-content {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        @media all and (min-width: $contentWidth) {
          text-align: left;
          width: 33%;
        }
      }

      .right-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .targetActivityItem {
          width: 350px;
          margin-bottom: 20px;

          @media all and (min-width: $break-point-tablet) {
            margin-bottom: 0;
          }
        }

        @media all and (min-width: $break-point-tablet) {
          display: grid;
          gap: 20px;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          width: 720px;
          margin: 0 auto;
        }

        @media all and (min-width: $contentWidth) {
          flex: 1;
        }
      }

      @media all and (min-width: $contentWidth) {
        flex-direction: row;
      }
    }

    @media all and (min-width: $contentWidth) {
      padding-top: 90px;
      padding-bottom: 90px;
    }
  }

  .section-partners {
    background: #fbfbfb;
    padding-top: 50px;
    padding-bottom: 50px;

    .section-content {
      @include section-content;

      .content-header {
        margin-bottom: 50px;
        text-align: center;

        @media all and (min-width: $contentWidth) {
          margin-bottom: 90px;
        }
      }

      .partner-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          margin-bottom: 30px;
          margin-right: 0;

          &.livability_challenge {
            width: 176.82px;
            height: 81.74px;
            background-image: url(../../../public/images/home/livability_challenge.png);
          }

          &.go4sdgs {
            width: 264.05px;
            height: 42.89px;
            background-image: url(../../../public/images/home/go4sdgs.png);
          }

          &.ixsa {
            width: 178.27px;
            height: 57.64px;
            background-image: url(../../../public/images/home/ixsa.png);
          }

          &.eb_impact {
            width: 191.67px;
            height: 50.93px;
            background-image: url(../../../public/images/home/eb_impact.png);
          }

          &.fundedhere {
            width: 184.97px;
            height: 61.66px;
            background-image: url(../../../public/images/home/fundedhere.png);
          }

          &.tembusu {
            width: 80.42px;
            height: 103.21px;
            background-image: url(../../../public/images/home/tembusu.png);
          }

          &.sg_alliance {
            width: 107.23px;
            height: 108.57px;
            background-image: url(../../../public/images/home/sg_alliance.png);
          }

          @media all and (min-width: $break-point-tablet) {
            margin-bottom: 0;

            &:not(:last-child) {
              margin-right: 60px;
            }
          }
        }

        @media all and (min-width: $break-point-tablet) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }

    @media all and (min-width: $contentWidth) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  .content-header {
    line-height: 115%;
  }
}

@import '../mixins.scss';
@import '../variables.scss';

.wizardView {
  @include section-content;
  // background: #ffffff;
  // box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  // border-radius: 3px;
  // padding: 0px 35px 40px;
  margin-bottom: 40px;
  .completeStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 0px 30px;
    margin: 0px auto;
    max-width: 520px;
    min-height: calc(100vh - 400px);
    min-height: calc(var(--vh, 1vh) * 100 - 400px);
    .completeImage {
      width: 182px;
      height: 151px;
      background-image: url(../../../public/images/wizard_complete.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 20px;
    }
    .completeTitle {
      font-family: Tiempo;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      color: #666666;
      margin-bottom: 20px;
    }
    .completeSubtitle {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #666666;
      width: 100%;
      max-width: 320px;
      margin-bottom: 30px;
      p {
        max-width: max-content;
        justify-content: start;
        width: 100%;
        text-align: left;
      }
    }
    button {
      font-size: 14px;
      width: 100%;
      max-width: 320px;
    }
  }
}

// span {
//   color: $secondaryTextColor;
// }

@media all and (max-width: 768px) {
  .wizardView {
  }
}
.add-new-organisation-div {
  margin-top: 30px;
  font-weight: bold;
  font-size: 32px;
  color: #333333;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.existing-new-organisation-div {
  margin-top: -87px;
}

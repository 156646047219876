@import '../_variables.scss';

.changePasswordView {
  background-color: $white;
  color: $secondaryTextColor;
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);

  &.embedded {
    box-shadow: none;
  }

  p {
    margin: 30px 0;
  }

  .textInput {
    margin-bottom: 20px;
  }

  button {
    width: 100%;
  }

  .title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .inputLabel {
    font-weight: 700;
    font-size: 14px;
    display: flex;
    margin-bottom: 8px;
  }

  .separationLine {
    margin: 0 25px;
    border-bottom: 1px solid $lightBackgroundColor;
  }

  .actionBtn {
    // font-weight: 700;
    // font-size: 14px;
    max-width: 210px;
    @media all and (max-width: $break-point-xs) {
      max-width: 100%;
    }
  }

  .row {
    display: flex;
    @media all and (max-width: $break-point-xs) {
      flex-direction: column;
    }
  }

  .col-50 {
    width: 50%;
    padding: 25px;
    @media all and (max-width: $break-point-xs) {
      width: 100%;
    }
  }
}

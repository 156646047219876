@import '../mixins.scss';
@import '../variables.scss';

.headerView {
  border-bottom: none;
  display: flex;
  justify-content: center;

  @media all and (min-width: $break-point-tablet) {
    border-bottom: 1px solid #cccccc;
  }

  .headerBlock {
    @include section-content;
    z-index: 3;
    width: 100%;
    bottom: initial;
    display: flex;
    flex-direction: column;
    // &::before {
    //   content: '';
    //   @include absolute-cover;
    //   opacity: 0.2;
    //   transform: matrix(1, 0, 0, -1, 0, 0);
    // }
  }

  .header {
    width: 100%;
    // height: 100px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: $break-point-tablet) {
      flex-direction: initial;
      padding-top: 0;
      height: 88px;
    }

    .headerLogo {
      flex-shrink: 0;
      height: 46px;
      width: 171px;
      margin-right: 22px;
      background-size: 171px 46px;
      cursor: pointer;
      background-image: url(../../../public/images/logo_black.png);
      background-repeat: no-repeat;
      background-position: center;

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url(../../../public/images/logo_black@2x.png);
        background-size: 171px 46px;
      }

      &.dark {
        background-image: url(../../../public/images/logo_black.png);

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          background-image: url(../../../public/images/logo_black@2x.png);
          background-size: 171px 46px;
        }
      }

      @media all and (max-width: $break-point-tablet) {
        margin: 10px 0;
        position: fixed;
        z-index: 19;
      }
    }

    .underlay {
      position: fixed;
      z-index: 18;
      height: 88px;
      width: 100%;
      background: $white;
      top: 0;
      left: 0;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.4);
    }

    .navToggle {
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: fixed;
      background-color: $primaryColor;
      top: 23px;
      right: 20px;
      z-index: 19;
      &:before {
        content: '';
        position: absolute;
        width: 24px;
        height: 2px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0.5em 0 0 $white, 0 1em 0 0 $white;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
      }
      @media all and (min-width: $break-point-sm) {
        display: none;
      }
    }

    .mobileMenuWrap {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      overflow: hidden;
      overflow-y: scroll;
      z-index: 9999;
      &.hidden {
        display: none;
      }
      .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .closeButton {
        position: absolute;
        top: 23px;
        right: 15px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        &:before,
        &:after {
          content: '';
          width: 24px;
          height: 2px;
          position: absolute;
          background-color: $secondaryColor;
          transform: rotate(45deg);
          top: 19px;
          left: 8px;
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    .mobileMenu {
      width: 100%;
      max-width: 320px;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: #fff;
      overflow-y: scroll;
      padding-bottom: 40px;
      .mobileLogoWrap {
        width: 100%;
        height: 75px;
        .headerLogo {
          margin-left: 20px;
          position: relative;
          &.dark {
            @media all and (max-width: $break-point-tablet) {
              margin: 20px 0 20px 30px;
            }
          }
        }
        a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .searchInput {
        margin: 10px 30px 20px;
        width: calc(100% - 60px);
      }
      .headerNavigation {
        display: block;
        margin-left: 0;
        padding: 0;

        .navItem {
          margin: 0 30px;
          border-bottom: solid 1px $outlineColor;
          a {
            width: 100%;
            padding: 16px 0 13px;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 500;
            font-size: $fontSizeSmall;
            line-height: 30px;
            letter-spacing: -0.03em;
            position: relative;
            display: block;
            &.active {
              &::after {
                transform: rotate(-135deg);
                margin-top: 10px;
              }
            }
          }
          &:last-child {
            margin-right: 30px;
            border-bottom: none;
          }
          &.button {
            // border: 1px solid $primaryColor;
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .navSeparator {
          display: none;
        }
      }
    }

    .headerSearch {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.05);
      border-radius: 60px;
      padding: 8px 17px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &::before {
        content: '';
        width: 17px;
        height: 17px;
        margin-right: 13px;
        background-image: url(../../../public/images/icons/search.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      input {
        appearance: none;
        border: none;
        background-color: transparent;
        border-radius: 0px;
        width: 100%;
        color: #333333;
        font-size: 14px;
        line-height: 18px;
        box-shadow: none;
        &::placeholder {
          color: #aaaaaa;
        }
      }
    }

    .headerNavigation {
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 0;
      .navItem {
        margin: 0 10px;
        cursor: pointer;
        font-size: $fontSizeSmall;
        line-height: 36px;
        font-style: normal;
        font-weight: 500;
        position: relative;
        // text-transform: capitalize;
        a {
          display: flex;
          text-decoration: none;
          color: $mainTextColor;

          &:hover {
            color: $primaryColor !important;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        &.button {
          color: $white;
          border: 1px solid $primaryColor;
          border-radius: 8px;
          padding: 1px 8px;
          width: 170px;
          text-align: center;
          background-color: #f26e26;
          font-size: $fontSizeMain;
        }
        .navItemImage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
          position: relative;
          z-index: 7;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        span {
          position: relative;
          z-index: 7;
          margin-left: 5px;
        }
        .dropdown-icon {
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 7px;
            background-color: $thirdTextColor;
            mask: url(../../../public/images/icons/caret_down.svg) no-repeat
              center / contain;
            top: 50%;
            right: -20px;
            transform: translateY(-50%);
            transition: all 0.1s linear;
            z-index: 7;
          }
        }
        .subNav {
          z-index: 1;
          position: absolute;
          border-radius: 19px;
          background-color: #fff;
          overflow: hidden;
          max-height: 0;
          transition: max-height 0.05s ease-in-out;
          width: 100%;
          right: 0;
          z-index: 6;
          top: 20px;
          // padding: 5px;

          @media all and (max-width: $break-point-tablet) {
            position: relative;
            max-height: initial;
            top: 0;
          }

          .navItem {
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;

            @media all and (max-width: $break-point-tablet) {
              padding: 2px 0;
            }

            a {
              width: 100%;
              padding: 10px 20px;
              transition: all 0.2s linear;
              color: $secondaryTextColor;
              display: block;
              @media all and (max-width: $break-point-tablet) {
                padding: 16px 0 13px;
                line-height: 30px;
              }
              &:before {
                display: none;
              }
              &:hover {
                background-color: whitesmoke;

                @media all and (max-width: $break-point-tablet) {
                  background: none;
                  color: $primaryColor;
                }
              }
            }
          }
        }
        &.nested:hover {
          .subNav {
            top: 0;
            padding-top: 40px;
            max-height: 320px;
            box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);

            @media all and (max-width: $break-point-tablet) {
              padding-top: 0;
              box-shadow: none;
              max-height: initial;
            }
          }
        }
      }
      .navSeparator {
        border-right: 1px solid #aaaaaa;
        opacity: 0.85;
        height: 17px;
        margin: 0px 10px;
      }
      .search-Icon {
        width: 17px;
        height: 17px;
        margin: 1px 21px;
        cursor: pointer;
        background-image: url(../../../public/images/icons/search-icon.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      .userNavItem {
        background-color: #fff;
        border-radius: 60px;
        margin-left: 20px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
        width: 153px;
        @media all and (max-width: $break-point-tablet) {
          margin-left: 0;
          box-shadow: none;
        }

        .navItem {
          margin: 0;
          padding: 5px 35px 5px 5px;

          @media all and (max-width: $break-point-tablet) {
            padding: 2px 0;
            margin: 0 30px;
          }

          a {
            align-items: center;
            line-height: 1;
            color: $secondaryTextColor;
            background: #fff;
            position: relative;
            z-index: 7;
            border-radius: 20px;

            .subscribed {
              margin-left: 5px;
              height: 17px;
              width: 52px;
              background-image: url(../../../public/images/home/pro.png);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }

            .subNav {
              top: 20px;
              .navItem {
                a {
                  line-height: 240%;
                }
              }
            }
          }

          &.nested {
            @media all and (max-width: $break-point-tablet) {
              & > a {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .headerSearchContainer {
    width: 100%;
    padding-bottom: 10px;
  }
}

.headerView
  .header
  .headerNavigation
  .navItem.subNavWithDropdown
  .dropdown-icon:after {
  display: none !important;
}
.appContent
  .headerView
  .header
  .headerNavigation
  .navItem.nested.subNavWithDropdown:hover,
.appContent
  .headerView
  .header
  .headerNavigation
  .navItem.nested.subNavWithDropdown {
  .dropdown-icon::after {
    display: none !important;
  }
  .subNav.subNavWithDropdown {
    // background: transparent !important;
    left: 0;
    min-width: 130px !important;
    // background-color: #fff !important;
    border-radius: 12px;
    // margin-left: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    a {
      padding: 0 !important;
      color: #666666 !important;
      // width: 240px;
      margin-left: 3px;
    }
    a:hover {
      background-color: transparent !important;
      color: $primaryColor !important;
    }
  }
}
.fullScreen .navItem.nested.subNavWithDropdown {
  .dropdown-icon::after {
    display: none !important;
  }
  .subNav.subNavWithDropdown {
    background: transparent !important;
    left: 0;
    min-width: 130px !important;
    background-color: #fff !important;
    border-radius: 12px;
    margin-left: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    a {
      padding: 0 !important;
      color: #444443 !important;
    }
    a:hover {
      background-color: transparent !important;
      color: $primaryColor !important;
    }
  }
}

@media all and (max-width: $break-point-tablet) {
  .headerView {
    .header {
      .headerNavigation {
        flex-wrap: wrap;
        padding: 10px 0;
        .navItem {
          margin: 8px 10px;
        }
      }
    }
  }
  .fullScreen
    .headerView
    .header
    .headerNavigation
    .navItem.subNavWithDropdown
    .subNav
    .navItem
    a {
    padding: 5px 0 5px !important;
    line-height: 30px;
    // color: #666 !important;
  }
  .fullScreen .navItem.nested.subNavWithDropdown > a {
    //margin-bottom: -15px;
    margin-top: 5px;
  }
  .fullScreen .navItem.subnav-dropdown {
    border-bottom: none !important;
    span {
      margin-left: 10px;
    }
  }
}

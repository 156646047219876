@import '../mixins.scss';
@import '../variables.scss';

#form {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: start;
  width: 100%;
  .error-message {
    color: red;
    font-size: 14px;
  }
  .no-border {
    border: none;
  }
  .w-full {
    width: 100%;
  }
  .text-input {
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    padding: 14px 11px;
  }
  .input-label {
    font-weight: 600;
    font-size: 18px;
  }
  .add-box-btn {
    color: #f26e26;
    padding: 0px !important;
    background-color: transparent !important;
  }
  .submit-section {
    display: flex;
    flex-direction: row;
    .button-block {
      border-top: 1px solid #eeeeee;
      padding-top: 32px;
      margin-top: 32px;
      .continue-btn {
        width: 210px;
        // font-weight: 500;
        // font-size: 14px;
        // line-height: 36px;
        // color: #ffffff;
        // text-transform: none;
      }
    }
  }
}

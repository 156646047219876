@import '../mixins.scss';
@import '../variables.scss';

.registerView {
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // background-image: url(../../../public/images/register/register_bg.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .registerLogo {
    height: 46px;
    width: 171px;
    margin: 30px 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #666666;
    text-align: center;
    background-image: url(../../../public/images/logo_black.png);
    background-size: 171px 46px;
    background-repeat: no-repeat;
    background-position: 0 center;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url(../../../public/images/logo_black.png);
    }
  }
  @media all and (min-width: 768px) {
    .registerContainer {
      display: flex;
      height: calc(100vh - 120px);
      align-items: center;
    }
  }
  .registerContent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 720px;
    padding: 0px 20px;
    align-items: center;
    margin-bottom: 60px;

    .registerTitle {
      margin-bottom: 16px;
      font-family: 'Tiempo';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      text-align: center;
      color: #444443;
    }
    .registerSubtitle {
      margin-bottom: 10px;
      font-style: normal;
      font-size: 18px;
      line-height: 30px;
      font-weight: 300;
      text-align: center;
      color: #444443;
    }
    .registerSelect {
      background: #ffffff;
      padding: 30px;
      border-radius: 3px;
      .registerSelectType {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        .registerType {
          width: 305px;
          height: 342px;
          border-radius: 5px;
          border: 2px solid #c4c4c4;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          padding: 18px 16px;
          &.expert {
            .registerTypeHeader {
              .typeIcon {
                background-image: url(../../../public/images/register/expert-gray.svg);
              }
            }
          }
          &.individual {
            .registerTypeHeader {
              .typeIcon {
                background-image: url(../../../public/images/register/individual-gray.svg);
              }
            }
          }
          &.selected {
            border: 2px solid #f26e26;
            &.expert {
              .registerTypeHeader {
                .typeIcon {
                  background-image: url(../../../public/images/register/expert-purple.svg);
                }
              }
              .registerTypeContent {
                ul.typeList {
                  li::before {
                    background-image: url(../../../public/images/register/check-circle-small-purple.svg);
                  }
                }
              }
            }
            &.individual {
              .registerTypeHeader {
                .typeIcon {
                  background-image: url(../../../public/images/register/individual-orange.svg);
                }
              }
              .registerTypeContent {
                ul.typeList {
                  li::before {
                    background-image: url(../../../public/images/register/check-circle-small-orange.svg);
                  }
                }
              }
            }
            .registerTypeHeader {
              .checkSelectedIcon {
                background-image: url(../../../public/images/register/check-circle-checked.svg);
              }
            }
            .registerTypeContent {
              .titleText {
                color: #666666;
                span.expertText {
                  color: #5000ff;
                }
              }
              ul.typeList {
                li {
                  color: #666666;
                }
              }
            }
            .registerTypeFooter {
              .infoIcon {
                background-image: url(../../../public/images/register/info-dark.svg);
              }
              .descText {
                color: #666666;
              }
            }
          }
          &:first-child {
            margin-right: 15px;
          }
          .registerTypeHeader {
            display: flex;
            justify-content: space-between;
            margin-bottom: 14px;
            .typeIcon {
              height: 33px;
              width: 33px;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: 50% 50%;
            }
            .checkSelectedIcon {
              height: 24px;
              width: 24px;
              background-image: url(../../../public/images/register/check-circle-unchecked.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: 50% 50%;
            }
          }
          .registerTypeContent {
            display: flex;
            flex-direction: column;
            flex: 1;
            .titleText {
              font-style: normal;
              font-weight: 700;
              font-size: 34px;
              line-height: 112.13%;
              letter-spacing: -0.01em;
              color: #999999;
              margin-bottom: 14px;
              span.expertText {
                color: #999999;
              }
            }
            ul.typeList {
              list-style: none;
              margin: 0;
              padding: 0;
              li {
                display: flex;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 20px;
                color: #999999;
                &:not(:last-child) {
                  margin-bottom: 9px;
                }
              }
              li:before {
                content: '';
                width: 12px;
                height: 13px;
                padding-right: 12px;
                margin-right: 11px;
                margin-top: 3px;
                background-image: url(../../../public/images/register/check-circle-small-gray.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: 50% 50%;
              }
            }
          }
          .registerTypeFooter {
            display: flex;
            align-items: center;
            border: 1px solid #c4c4c4;
            border-radius: 5px;
            height: 31px;
            padding-left: 10px;
            .infoIcon {
              width: 12px;
              height: 13px;
              background-image: url(../../../public/images/register/info-gray.svg);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: 50% 50%;
              margin-right: 14px;
            }
            .descText {
              font-style: normal;
              font-size: 12px;
              line-height: 20px;
              color: #999999;
            }
          }
          // .typeIcon {
          //   margin-top: auto;
          //   margin-bottom: 38px;
          //   width: 54px;
          //   height: 50px;
          //   background-position: center;
          //   background-repeat: no-repeat;
          //   &.individual {
          //     background-image: url(../../../public/images/register/individual.svg);
          //   }
          //   &.organisation {
          //     background-image: url(../../../public/images/register/organisation.svg);
          //   }
          //   &.expert {
          //     background-image: url(../../../public/images/register/expert.svg);
          //   }
          // }
          // span {
          //   font-size: 14px;
          //   line-height: 22px;
          //   text-align: center;
          //   color: #666666;
          // }
          // .label-wrapper {
          //   display: flex;
          //   flex-direction: row;
          //   label {
          //     font-family: Tiempo;
          //     font-weight: 600;
          //     font-size: 30px;
          //     line-height: 38px;
          //     text-align: center;
          //     color: #666666;
          //   }
          // }
          // button {
          //   margin-top: 42px;
          //   width: 100%;
          // }
        }
      }
      button {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 36px;
        width: 100%;
      }
    }
  }
  .registerForm {
    width: 530px;
    max-width: calc(100% - 30px);
    padding: 20px;
    background: #ffffff;
    border: solid 1px #cccccc;
    border-radius: 3px;
    position: relative;
    .disclaimer-header {
      display: flex;
      flex-direction: row;
      height: 50px;
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
      .main-logo {
        height: 50px;
        width: 240px;
        background-image: url(../../../public/images/logo_black.png);
        background-repeat: no-repeat;
      }
      .spacer {
        flex-grow: 1;
      }
      .lynk-logo {
        height: 40px;
        width: 100px;
        background-repeat: no-repeat;
        background-image: url(../../../public/images/lynk-logo.png);
      }
    }
    .backButton {
      position: absolute;
      top: 10px;
      left: 20px;
      padding: 5px 10px 5px 18px;
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;
      color: #444443;
      cursor: pointer;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 9px;
        left: 0px;
        width: 6px;
        height: 6px;
        border-left: 2px solid #444443;
        border-top: 2px solid #444443;
        transform: translate(50%, 100%) rotate(-45deg);
      }
    }
    .formContent {
      width: 100%;
      max-width: 355px;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .disclaimer-form-body {
        padding-top: 10%;
        padding-bottom: 10%;
        p {
          color: #444443;
          line-height: normal;
        }
      }
      .formTitle {
        width: 100%;
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #444443;
      }
      label {
        margin: 10px 0px 2px;
        font-weight: 600;
        font-size: 14px;
        line-height: 30px;
        color: #444443;
      }
      .textInput {
        width: 100%;
      }
      button {
        width: 100%;
        margin-top: 22px;
        font-size: 16px;
      }
      .disclaimer-button {
        align-self: center;
        width: 70%;
      }
      p {
        font-size: 12px;
        line-height: 2.5;
        margin-top: 15px;
        color: #666666;
        margin-left: 0;
        margin-right: 0;

        @media all and (min-width: $break-point-xs) {
          margin-left: -32px;
          margin-right: -32px;
        }

        span {
          color: $primaryColor;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .registerView {
    .registerLogo {
      margin-bottom: 42px;
    }
    .registerContent {
      .registerTitle {
        font-size: 32px;
        line-height: 38px;
      }
      .registerSelect {
        flex-direction: column;
      }
    }
    .registerForm {
    }
  }
}

button.btn-calendar {
  background-color: transparent !important;
  align-self: center;
  justify-self: center;
  place-self: center;
  svg {
    padding: 0px;
    margin: 0px;
  }
}

@import '../../_variables.scss';

.textInput {
  padding: 0;
  position: relative;
  &.simplified {
    input,
    input[readonly],
    textarea,
    textarea[readonly] {
      border: none;
      border-radius: 0px;
      font-size: 16px;
      background-color: transparent !important;
      cursor: default !important;
    }
  }
  input,
  textarea {
    appearance: none;
    border: solid 1px $outlineColor;
    background-color: #fff;
    border-radius: 3px;
    width: 100%;
    color: #333333;
    padding: 14px 11px;
    font-size: 14px;
    line-height: 18px;
    box-shadow: none;
    &::placeholder {
      color: #cccccc;
    }
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:disabled {
      background-color: #f5f5f5;
    }
  }
  .toggleVisibility {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;
    display: block;
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      display: block;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 12px;
      background-color: $color2;
      mask: url(../../../../public/images/icons/view.png) no-repeat center /
        contain;
    }
    &.active {
      &:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 1px;
        background-color: $color2;
        top: 75%;
        left: 50%;
        display: block;
        transform: rotate(45deg) translate(-75%, -52%);
      }
    }
  }
  textarea {
    resize: none;
    min-height: 10px;
  }
  .inputError {
    text-align: left;
    padding-left: 12px;
    font-size: 12px;
    color: $errorColor;
  }
}

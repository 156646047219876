@import '../mixins.scss';
@import '../variables.scss';

.profileView {
  width: 100%;
  // background: #ffffff;
  // box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  // border-radius: 3px;
  position: relative;

  .profileBanner {
    width: 100%;
    padding-top: 42.4%;
    background-size: cover;
    margin-bottom: -76px;
    position: relative;
    z-index: 2;
    @media all and (max-width: 768px) {
      margin-bottom: -60px;
    }
    span {
      display: block;
      position: absolute;
      width: 59px;
      height: 59px;
      background-image: url(../../../public/images/icons/play.svg);
      top: 50%;
      left: 50%;
      cursor: pointer;
      transform: translate(-50%, -50%);
      transition: all 0.2s linear;
      &:hover {
        opacity: 0.85;
      }
    }
  }

  .container {
    @include section-content;
    // padding: 36px 38px;
    margin-top: 30px;
    margin-bottom: 30px;

    .profileHeaderPlaceholderItem {
      max-width: 400px;
      margin-bottom: 13px;
    }

    .profileHeader {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      // z-index: 3;
      flex-wrap: wrap;
      position: relative;
      @media all and (max-width: $break-point-xs) {
        display: block;
      }
      .profileImage {
        margin-right: 35px;
        position: relative;
        z-index: 9;
        @media all and (max-width: $break-point-xs) {
          margin: 0 auto 0;
          &.ownProfile {
            margin-top: 40px;
          }
        }
      }
      .profileName {
        font-size: 42px;
        line-height: 1.2;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0 10px;
        @media all and (max-width: 768px) {
          justify-content: center;
        }
        .profileNameHeader {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          @media all and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
          }
          .profile_status {
            color: #8a8a8a;
            margin-left: 5px;
            line-height: 21px;
            font-style: normal;
            display: flex;
            align-items: baseline;
            flex-direction: row;
            font-weight: 400;
            font-size: 18px;
            .profile_status_label {
              margin-left: 10px;
            }
          }
        }
        .subscribed {
          margin-left: 10px;
          height: 30px;
          width: 61px;
          background-image: url(../../../public/images/home/pro.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
      .profileSubtitle {
        font-size: 18px;
        line-height: 2;
        color: $secondaryTextColor;
      }
      .profileLocation {
        font-size: 18px;
        line-height: 2;
        color: $thirdTextColor;
        position: relative;
        padding-left: 24px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 14px;
          height: 17px;
          mask: url(../../../public/images/icons/location.svg) no-repeat center;
          mask-size: contain;
          background-color: $thirdTextColor;
          left: 0;
          top: 50%;
          margin-top: -8px;
        }
      }
      .editProfileBtn {
        font-size: $fontSizeMain;
        line-height: 260%;
        text-align: center;
        color: $thirdTextColor;
        border: 1px solid $thirdTextColor;
        border-radius: 50px;
        cursor: pointer;
        padding: 1px 40px;
        position: absolute;
        z-index: 2;
        top: 20px;
        right: 20px;
        transition: all 0.2s linear;
        background-color: #fff;
        &:hover {
          border-color: $secondaryTextColor;
          color: $secondaryTextColor;
          background-color: $mainBackgroundColor;
        }
        @media all and (max-width: $break-point-xs) {
          width: 180px;
          right: calc(50% - 90px);
        }
      }
    }
    .flex {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-top: 35px;
      flex-wrap: wrap;
      .left {
        width: 66%;
        @media all and (max-width: 768px) {
          width: 100%;
          order: 2;
        }
        .experience-label {
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 30px;
          color: #666666;
          margin-top: 10px;
        }
        .timeline-container {
          margin-top: 20px;
        }
        .tags-container {
          margin-top: 30px;
          display: flex;
          flex-wrap: wrap;
          .tag {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 36px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
            background: #f26e26;
            border-radius: 30px;
            height: 50px;
            padding-left: 30px;
            padding-right: 30px;
            &:not(:last-child) {
              margin-right: 18px;
            }
          }
        }
        .profileAboutPlaceholderItem {
          margin: 10px 0 30px;
        }

        .about {
          font-size: 18px;
          line-height: 30px;
          color: $secondaryTextColor;
          p {
            margin-bottom: 50px;
            white-space: pre-line;
          }
        }
        .interestedTitle,
        .solutionsTitle {
          font-size: 30px;
          line-height: 30px;
          color: $secondaryTextColor;
          margin-bottom: 25px;
        }

        .goalsTable {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 1px;
          @media all and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media all and (max-width: 1400px) {
            grid-template-columns: repeat(4, 1fr);
          }
          @media all and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        .solutionsContainer {
          margin-top: 50px;

          .solutionItem {
            padding: 20px 30px;
            border: 1px solid $lightBackgroundColor;
            border-radius: 3px;

            & + .solutionItem {
              margin-top: 20px;
            }

            .solutionTags {
              color: $thirdTextColor;
              font-weight: $fontWeightBold;
              text-transform: capitalize;
              font-size: 12px;

              .spacer {
                margin: 0 7px;
              }
            }

            .solutionName {
              color: $secondaryTextColor;
              font-weight: $fontWeightSemibold;
              font-size: 18px;
              line-height: 57%;
              margin: 20px 0;
            }

            .solutionDescription {
              line-height: 126%;
              font-size: 14px;
              color: $secondaryTextColor;
            }
          }
        }

        .postsTimeline {
          .post {
            padding: 0 0 20px;
            box-shadow: none;
            border-radius: 0;
            border-bottom: 1px solid $lightBackgroundColor;
          }
          .loadMore {
            width: 100%;
            background-color: $mainBackgroundColor;
            border-radius: 5px;
            padding: 12px 20px;
            text-align: center;
            text-transform: uppercase;
            color: $primaryColor;
            font-weight: bold;
            font-size: 0.8rem;
            cursor: pointer;
            transition: all 0.2s linear;
            &:hover {
              background-color: $primaryColor;
              color: $mainBackgroundColor;
            }
          }
        }
      }
      .right {
        width: 33%;
        padding-left: 50px;
        @media all and (max-width: 768px) {
          width: 100%;
          padding-left: 0;
          padding-bottom: 40px;
          order: 1;
          display: flex;
          flex-wrap: wrap-reverse;
          flex-direction: column;
        }

        .lynk-container {
          display: flex;
          flex-direction: column;
          .lynk-button {
            width: 100%;
            height: 50px;
            background: #5000ff;
            color: #ffffff;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            border-radius: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            margin-bottom: 20px;
            cursor: pointer;
            .lynk-button-icon {
              width: 33px;
              height: 10px;
              background-image: url(../../../public/images/lynk_icon_white.svg);
              background-size: contain;
              margin-left: 20px;
            }
            .lynk-button-text {
              flex: 1;
              display: flex;
              justify-content: center;
            }
            &.secondary {
              background: #ffffff;
              color: #5000ff;
              border: 1px solid #5000ff;
              .lynk-button-icon {
                background-image: url(../../../public/images/lynk_icon_purple.svg);
              }
            }
          }
        }

        .contacts {
          position: relative;
          width: 100%;

          div {
            color: $thirdTextColor;
            padding-left: 38px;
            font-weight: 600;
            font-size: 14px;
            position: relative;
            margin: 10px 0 30px;
            cursor: pointer;
            transition: all 0.2s linear;
            min-height: 20px;

            &.profileContactsPlaceholderItem {
              padding-left: 16px;
            }

            &:hover {
              color: $mainTextColor;
              &:before {
                background-color: $mainTextColor;
              }
            }
            &:before {
              content: '';
              display: block;
              position: absolute;
              background-color: $thirdTextColor;
              left: 0;
              top: 50%;
              transition: all 0.2s linear;
            }
            &.web {
              &:before {
                width: 21px;
                height: 21px;
                mask: url(../../../public/images/icons/web.svg) no-repeat center;
                mask-size: contain;
                margin-top: -10px;
              }
            }
            &.twitter {
              &:before {
                width: 21px;
                height: 21px;
                mask: url(../../../public/images/icons/twitter.svg) no-repeat
                  center;
                mask-size: contain;
                margin-top: -10px;
              }
            }
            &.linkedin {
              &:before {
                width: 21px;
                height: 21px;
                mask: url(../../../public/images/icons/linkedin.svg) no-repeat
                  center;
                mask-size: contain;
                margin-top: -10px;
              }
            }
            &.facebook {
              &:before {
                width: 21px;
                height: 21px;
                mask: url(../../../public/images/icons/facebook.svg) no-repeat
                  center;
                mask-size: contain;
                margin-top: -10px;
              }
            }
            &.instagram {
              &:before {
                width: 21px;
                height: 21px;
                mask: url(../../../public/images/icons/instagram.svg) no-repeat
                  center;
                mask-size: contain;
                margin-top: -10px;
              }
            }
          }
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .connect {
          @media all and (max-width: 768px) {
            padding-bottom: 40px;
          }

          .blockTitle {
            font-size: 30px;
            line-height: 30px;
            color: $secondaryTextColor;
            margin-bottom: 20px;
          }

          .connectItem {
            width: 100%;
            background: $mainBackgroundColor;
            border-radius: 20px;
            color: $secondaryTextColor;
            padding: 0 18px;
            line-height: 40px;
            margin-bottom: 15px;
          }

          .profileLookingToPlaceholderItem {
            margin-bottom: 10px;
          }

          .connectButton {
            border-radius: 30px;
            font-weight: 700;
            font-size: 14px;
            line-height: 36px;
            width: 100%;
            padding: 7px 30px;
          }
        }

        .claim_remove_link {
          margin-top: -82px;
          position: absolute;
          @media all and (max-width: 768px) {
            padding-bottom: 40px;
            margin-top: -30px;
          }

          .link_div {
            font-size: 15px;
            font-weight: bold;
            line-height: 15px;
            margin-top: 20px;
          }
          .claim_remove_btn {
            color: $secondaryTextColor;
          }
        }
      }
    }
  }
}

@import '../mixins.scss';
@import '../variables.scss';

.app.events-page,
.app.directory-page,
.app.add-directory-page {
  min-height: 100vh;

  .aboutView {
    min-height: 100vh;
  }

  iframe {
    border: 0;
  }

  .content a {
    color: #f26e26;
  }
}

.aboutView,
.event-page,
.add-directory-page,
.directory-page {
  display: flex;
  flex-direction: column;
  position: relative;

  &.background {
    background-color: $white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      // background-image: url(../../../public/images/about/bg@2x.jpg);
    }

    &.webp {
      // background-image: url(../../../public/images/about/bg.webp);
      // @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      //   background-image: url(../../../public/images/about/bg@2x.webp);
      // }
    }

    .content {
      @include section-content;
      position: relative;
      width: 100%;
      // max-width: 1035px;
      background-color: $white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-style: normal !important;
      // padding-top: 111px;
      margin: 0 auto;
      margin-top: 3%;

      /* Container for flexboxes */
      .row-1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      /* Create four equal columns */
      .column {
        flex: 50%;
        padding: 20px;
      }
      .column-1 {
        flex: 63%;
        padding: 20px;
      }
      .column-2 {
        flex: 36%;
        padding: 20px;
      }

      .column-3 {
        width: 40%;
        padding: 20px;
      }
      .column-4 {
        width: 45%;
        padding: 20px;
      }
      .btn-mg {
        margin-top: 20px;
      }

      .p-1 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 30px !important;
        line-height: 29px !important;
        display: flex;
        align-items: center;
        color: #444443;
      }
      .span-ml {
        margin-left: 10px;
      }
      .span-1 {
        margin: 14px;
      }

      /* On screens that are 992px wide or less, go from four columns to two columns */
      @media all and (max-width: $break-point-md) and (max-width: $break-point-xs) and (max-width: 768px) {
        .column {
          flex: 100%;
          height: 580px;
        }
        .column-1 {
          flex: 100%;
          padding: 20px;
        }
        .column-2 {
          flex: 100%;
          padding: 20px;
        }
        .column-3 {
          flex: 100%;
          padding: 20px;
        }
        .column-4 {
          flex: 100%;
          padding: 20px;
        }
        .p-1 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 29px !important;
          display: flex;
          align-items: center;
          color: #444443;
        }
        .span-1 {
          margin: 14px;
          font-size: 20px;
        }
      }

      /* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
      @media screen and (max-width: 600px) {
        .row {
          flex-direction: column;
        }
      }
      .title {
        font-size: 48px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 3%;

        @media all and (max-width: $break-point-md) and (max-width: $break-point-xs) and (max-width: 768px) {
          // margin: 30px 45px;
          font-size: 42px;
          font-weight: 700;
          font-style: normal;
        }
      }
      .title-1 {
        font-size: 36px;
        font-weight: 700;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 3%;

        @media all and (max-width: $break-point-md) and (max-width: $break-point-xs) and (max-width: 768px) {
          // margin: 30px 45px;
          font-size: 36px;
          font-weight: 700;
          font-style: normal;
        }
      }
      .joinTitle {
        font-weight: 700;
        font-size: 50px;
        line-height: 55px;
        margin-bottom: 3%;
        @media all and (max-width: $break-point-md) and (max-width: $break-point-xs) and (max-width: 768px) {
          font-size: 44px;
        }
      }

      .section {
        // margin-top: 20px;
        p {
          line-height: 162%;
          margin-bottom: 20px;

          @media all and (max-width: $break-point-md) and (max-width: $break-point-xs) and (max-width: 768px) {
            // margin: 20px 45px 0 45px;
          }

          span {
            color: $primaryColor;
          }
        }

        .bigTextSize {
          font-size: 24px;
          line-height: 30px;
        }
        .mg-sub-section {
          margin: 7%;
        }
        .p-bold {
          font-weight: bold;
          color: #444443;
        }
        .sub-section-image-1 {
          height: 100%;

          &.background {
            background-image: url(../../../public/images/about/about-img-1.png);
            background-color: white;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center 0;
            @media screen and (max-width: 600px) {
              height: 300px;
            }
          }
        }
        .sub-section-image-2 {
          height: 100%;
          &.background {
            background-image: url(../../../public/images/about/about-img-2.png);
            background-color: white;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center 0;
            @media screen and (max-width: 789px) {
              height: 300px;
            }
          }
        }
        .sub-section-image-3 {
          height: 100%;
          &.background {
            background-image: url(../../../public/images/about/about-img-3.png);
            background-color: white;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center 0;
            @media screen and (max-width: 792px) {
              height: 300px;
            }
          }
        }
        .sub-section-image-4 {
          height: 100%;
          &.background {
            background-image: url(../../../public/images/about/about-img-4.png);
            background-color: white;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right 0;
            @media screen and (max-width: 600px) {
              height: 200px;
              background-position: center 0;
            }
          }
        }
        .diveder-line {
          width: 100%;
          height: 0px;
          border-bottom: 1px solid #cccccc;
          position: absolute;
        }
      }
      .mg-5 {
        font-size: 18px;
        font-style: normal;
        line-height: 36px;
        color: #444443;
        @media all and (max-width: $break-point-md) {
          margin: 4%;
        }
      }
      .btn-img {
        color: $white;
        font-style: normal;
        font-size: 18px;
        line-height: 36px;
        margin-top: 22px;
        border-radius: 8px;
        @media all and (max-width: $break-point-md) {
          margin: 4%;
          margin-left: 18%;
        }
      }
      .right-start-div {
        height: 392px;
        width: 100%;
      }
      .width-100 {
        width: 100%;
      }
    }
  }
}

@import '../mixins.scss';
@import '../variables.scss';

.section-404-not-found {
  height: 200px;

  @media all and (min-width: $break-point-xs) {
    height: 400px;
  }

  .section-content {
    @include section-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@import '../../_variables.scss';

.contentContainer {
  margin-bottom: $spaceMain;
  // padding: $spaceMain $spaceHorizontal;
  // background: $white;
  // box-shadow: $shadowContainer;
  // border-radius: $radiusMain;

  &:last-child {
    margin-bottom: 0;
  }
}

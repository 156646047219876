@import '../_mixins.scss';
@import '../_variables.scss';

.feedView {
  @include section-content;
}

.feed {
  display: flex;

  @media all and (max-width: $break-point-xs) {
    flex-wrap: wrap;
  }

  .feedTimeline {
    @include section-content;
    // width: calc(100% - 330px);

    @media all and (max-width: $break-point-xs) {
      width: 100%;
    }

    .postContentPlaceholder {
      width: 100%;
      background: $white;
      margin-bottom: 20px;
      padding-top: 20px;
      padding-left: 20px;
    }
  }
}

@import '../../variables.scss';
@import '../../mixins.scss';

.targetActivityItem {
  position: relative;
  background-color: $mainBackgroundColor;
  color: $secondaryTextColor;
  border-radius: 3px;
  padding: 12px 25px;
  height: 110px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px;
  cursor: pointer;
  &.selected {
    background-color: $primaryColor;
    color: #ffffff;
  }
  &.link {
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #444443;
      mask: url(../../../../public/images/icons/caret_right.svg) no-repeat
        center / contain;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      transition: all 0.1s linear;
    }
    &:hover:after {
      right: 12px;
    }
  }
  .Raise-Funding,
  .Raise-Funds {
    width: 36px;
    height: 31px;
    margin-bottom: -22px;
    background-size: 100%;
    background-repeat: no-repeat;
    // background-position: center;
    background-image: url(../../../../public/images//icons/objectives/fund-icon.png);
  }
  .Invest,
  .To-invest {
    width: 36px;
    height: 31px;
    margin-bottom: -22px;
    background-size: 100%;
    background-repeat: no-repeat;
    // background-position: center;
    background-image: url(../../../../public/images//icons/objectives/invest.png);
  }
  .Partner,
  .To-partner {
    width: 36px;
    height: 31px;
    margin-bottom: -22px;
    background-size: 100%;
    background-repeat: no-repeat;
    // background-position: center;
    background-image: url(../../../../public/images//icons/objectives/patner.png);
  }
  .Join-an-Organisation {
    width: 36px;
    height: 31px;
    margin-bottom: -22px;
    background-size: 100%;
    background-repeat: no-repeat;
    // background-position: center;
    background-image: url(../../../../public/images//icons/objectives/join-organization.png);
  }
  .Mentor-or-Advise {
    width: 36px;
    height: 31px;
    margin-bottom: -22px;
    background-size: 100%;
    background-repeat: no-repeat;
    // background-position: center;
    background-image: url(../../../../public/images//icons/objectives/mentor.png);
  }
  .Hiring,
  .Hire {
    width: 36px;
    height: 31px;
    margin-bottom: -22px;
    background-size: 100%;
    background-repeat: no-repeat;
    // background-position: center;
    background-image: url(../../../../public/images//icons/objectives/hire.png);
  }
  .link-height {
    height: 35px;
    margin-top: 6px;
  }
  .Merge-or-Acquire {
    width: 36px;
    height: 31px;
    margin-bottom: -22px;
    background-size: 100%;
    background-repeat: no-repeat;
    // background-position: center;
    background-image: url(../../../../public/images//icons/objectives/merge.png);
  }
  .Market-Maps {
    width: 36px;
    height: 31px;
    margin-bottom: -22px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../../../../public/images//icons/objectives/market-maps.png);
  }
  .itemTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    margin-left: 40px;
    color: inherit;
    // .img {
    //   background-size: 100%;
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   background-image: url(../../../../public/images//icons/fund-icon.png);
    // }
  }
  .itemSubtitle {
    margin-top: 6px;
    font-size: 12px;
    line-height: 16px;
    color: inherit;
    // text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .selectedIndicator {
    position: absolute;
    top: 14px;
    right: 21px;
  }
}

@media all and (max-width: 768px) {
  .targetActivityItem {
    padding: 15px 20px;
  }
}

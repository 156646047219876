@import '../mixins.scss';
@import '../variables.scss';

.listOrganizationsView {
  width: 100%;

  section {
    @include section-content;
    // background: #fff;
    // box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    // border-radius: 3px;
    // padding: 30px 35px 5px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;

    @media all and (max-width: $break-point-xs) {
      flex-wrap: wrap;
    }

    .header {
      font-size: 30px;
      line-height: 30px;
      color: $secondaryTextColor;
      font-weight: bold;
      margin-bottom: 12px;
    }
    .subheader {
      font-size: 14px;
      line-height: 114%;
      color: $secondaryTextColor;
    }
  }

  .userPlaceholderContainer {
    display: flex;

    .userPlaceholderItem {
      position: relative;
      width: 25%;
    }
  }

  .listOrganizationsContainer {
    margin: -14px;
    display: flex;
    flex-wrap: wrap;

    &.noOrganizations {
      margin: 0;
      display: block;
    }

    @media all and (max-width: $break-point-xs) {
      margin-left: 0;
      margin-right: 0;
    }

    .profileCard {
      cursor: pointer;
      transition: all 0.15s linear;

      &:hover {
        filter: brightness(97%);
      }

      &.addNew {
        border: 2px dashed $dividerDark;
        background: transparent;
        display: flex;
        align-items: center;
        min-height: 258px;

        &:hover {
          opacity: 0.7;
        }
      }

      .text {
        color: $primaryColor;
        font-weight: $fontWeightBold;

        &:before {
          content: '';
          display: block;
          width: 30px;
          height: 30px;
          margin: 15px auto;
          background-image: url(../../../public/images/icons/plus.svg);
        }
      }
      .existingText {
        color: $primaryColor;
        font-weight: $fontWeightBold;

        &:before {
          content: '';
          display: block;
          width: 30px;
          height: 30px;
          margin: 15px auto;
        }
      }
    }
  }

  .noOrganizations {
    color: $secondaryTextColor;
    font-size: 18px;
    margin-bottom: 25px;
  }

  .organizationsControls {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $lightBackgroundColor;
  }

  button {
    background-color: $primaryColor;
    border-color: $primaryColor;
    color: #fff;
    font-size: $fontSizeMain;
    text-align: center;
    cursor: pointer;
    line-height: 257%;
    margin-bottom: 30px;
  }
}

@import '../../_variables.scss';

.commentContainer {
  display: flex;
  margin-bottom: $spaceSmall;

  &:last-child {
    margin-bottom: 0;
  }

  .commentAvatar {
    margin-right: $spaceMedium;
    cursor: pointer;
  }

  .comment {
    padding: $spaceMain;
    position: relative;
    border-radius: $radiusBig;
    background-color: $mainBackgroundColor;
    max-width: calc(100% - 64px);

    .commentMeta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $spaceSmallest;

      .commentDate {
        color: $thirdTextColor;
        line-height: 1;
        margin-left: $spaceMain;
      }

      .commentUser {
        font-weight: $fontWeightBold;
        line-height: 1;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        .subscribed {
          margin-left: 5px;
          height: 17px;
          width: 35px;
          background-image: url(../../../../public/images/home/pro.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
    }
    .commentContent {
      img {
        padding-top: 20px;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}

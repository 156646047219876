@import '../../variables.scss';
@import '../../mixins.scss';

.goalCard {
  box-shadow: 0 0 0 1px #cccccc;
  padding-top: 120%;
  position: relative;
  cursor: pointer;
  border-top: 10px solid #dddddd;
  color: #999999;
  background-color: $mainBackgroundColor;

  .goalCardContent {
    .goalImage {
      opacity: 0.5;
      transition: all 0.2s linear;
    }
  }

  &:hover,
  &.active,
  &.selected {
    .goalCardContent {
      .goalImage {
        opacity: 1;
      }
    }
  }

  &.selected {
    background-color: #ffffff;
  }

  &.selectable {
    color: $thirdTextColor !important;
    border-color: #dddddd !important;
    .goalCardContent {
      .goalImage {
        opacity: 0.5;
      }
    }
  }
}

.goalCardContent {
  @include absolute-cover;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  color: inherit;
  background-size: 0px;
  background-repeat: no-repeat;
  background-position: center calc(50% + 15px);
  &.other-sustainability-solutions, &.all-sdgs{
    display: none;
  }

  label {
    text-transform: uppercase;
    z-index: 1;
  }

  .selectedIndicator {
    position: absolute;
    right: 14px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .goalImage {
    position: absolute;
    top: 52px;
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    background-color: #666666;
    &.sdg1-no-poverty {
      mask: url(../../../../public/images/goals/poverty.png) no-repeat center;
      mask-size: 81px;
    }
    &.sdg2-zero-hunger {
      mask: url(../../../../public/images/goals/hunger.png) no-repeat center;
      mask-size: 45px;
    }
    &.sdg3-good-health-and-well-being {
      mask: url(../../../../public/images/goals/health.png) no-repeat center;
      mask-size: 43px;
    }
    &.sdg4-quality-education {
      mask: url(../../../../public/images/goals/education.png) no-repeat center;
      mask-size: 45px;
    }
    &.sdg5-gender-equality {
      mask: url(../../../../public/images/goals/gender_equality.png) no-repeat
        center;
      mask-size: 28px;
    }
    &.sdg6-clean-water-and-sanitation {
      mask: url(../../../../public/images/goals/water.png) no-repeat center;
      mask-size: 27px;
    }
    &.sdg7-affordable-and-clean-energy {
      mask: url(../../../../public/images/goals/energy.png) no-repeat center;
      mask-size: 45px;
    }
    &.sdg8-decent-work-and-economic-growth {
      mask: url(../../../../public/images/goals/economic_growth.png) no-repeat
        center;
      mask-size: 45px;
    }
    &.sdg9-industry-innovation-and-infrastructure {
      mask: url(../../../../public/images/goals/infrastructure.png) no-repeat
        center;
      mask-size: 45px;
    }
    &.sdg10-reduced-inequalities {
      mask: url(../../../../public/images/goals/inequality.png) no-repeat center;
      mask-size: 45px;
    }
    &.sdg11-sustainable-cities-and-communities {
      mask: url(../../../../public/images/goals/cities.png) no-repeat center;
      mask-size: 45px;
    }
    &.sdg12-responsible-consumption-and-production {
      mask: url(../../../../public/images/goals/consumption.png) no-repeat
        center;
      mask-size: 45px;
    }
    &.sdg13-climate-action {
      mask: url(../../../../public/images/goals/climate.png) no-repeat center;
      mask-size: 45px;
    }
    &.sdg14-life-below-water {
      mask: url(../../../../public/images/goals/oceans.png) no-repeat center;
      mask-size: 45px;
    }
    &.sdg15-life-on-land {
      mask: url(../../../../public/images/goals/biodiversity.png) no-repeat
        center;
      mask-size: 45px;
    }
    &.sdg16-peace-justice-and-strong-institutions {
      mask: url(../../../../public/images/goals/peace.png) no-repeat center;
      mask-size: 45px;
    }
    &.sdg17-partnerships-for-the-goals {
      mask: url(../../../../public/images/goals/partnerships.png) no-repeat
        center;
      mask-size: 45px;
    }
    &.other-sustainability-solutions {
      mask: url(../../../../public/images/goals/other_sustainability_solutions.png) no-repeat
      center;
      mask-size: 45px;
    }
    &.all-sdgs {
      mask: url(../../../../public/images/goals/All.png) no-repeat
      center;
      mask-size: 45px;
    }
  }
  &.infrastructure {
    background-image: url(../../../../public/images/home/goal_infrastructure.png);
  }

  .followers {
    font-size: 14px;
    line-height: 214%;
    color: $thirdTextColor;
    font-weight: normal;
    position: absolute;
    bottom: 5px;
  }
}

@media all and (max-width: 768px) {
  .goalCard {
    .goalCardContent {
      padding: 8px;
      label {
        font-size: 12px;
      }
      .goalImage {
        top: 44px;
      }
    }
  }
}

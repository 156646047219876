@import '../mixins.scss';
@import '../variables.scss';

.editStaffView {
  width: 100%;
  margin-top: $spaceHorizontal;

  .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    @media all and (max-width: $break-point-xs) {
      flex-wrap: wrap;
    }

    .header {
      font-size: 30px;
      line-height: 30px;
      color: $secondaryTextColor;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .subheader {
      font-size: 14px;
      line-height: 114%;
      color: $secondaryTextColor;
    }
  }

  .filters {
    margin-bottom: 25px;
  }

  .listStaffContainer {
    margin: -14px;
    display: flex;
    flex-wrap: wrap;

    &.noStaff {
      margin: 0;
      display: block;
    }

    @media all and (max-width: $break-point-xs) {
      margin-left: 0;
      margin-right: 0;
    }

    .profileCard {
      cursor: pointer;
    }
  }

  .noStaff {
    color: $secondaryTextColor;
    font-size: 18px;
    margin-bottom: 25px;
  }

  .actions {
    border-top: 1px solid $lightBackgroundColor;
    margin-top: 25px;
    padding-top: 25px;

    .saveButton {
      background-color: $primaryColor;
      border-color: $primaryColor;
      color: #fff;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      line-height: 257%;
      margin-bottom: 10px;
    }
  }
}

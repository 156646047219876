.expert-detail-input-form {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;

  .input-group {
    padding: 20px;
    flex-grow: 1;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    .expanded-section {
      .error-message {
        color: red;
        font-size: 14px;
      }
    }
    .expand-toggle-btn {
      color: #f26e26;
      font-style: normal;
      align-self: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      cursor: pointer;
    }
    .date-input-group {
      display: flex;
      flex-direction: row;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 3px;
      .text-input {
        border: none;
      }

      .calendar-btn {
        background-color: transparent;
      }
      .rdt {
        border: 0.1px solid #cccccc;
        flex-grow: 1;
        .form-control {
          border: none;
          height: 50px;
          width: 100%;
        }
      }
    }
    .input-label {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #666666;
    }

    .spacer {
      margin-left: 5px;
      margin-right: 5px;
    }
    .dates-section {
      display: flex;
      flex-direction: row;
      .date-input {
        flex-grow: 1;
      }
    }
  }
  .delete-btn {
    background-color: transparent !important;
    color: #e91c2d;
    align-self: start;
    margin-top: 30px;
  }
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-top: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .checkbox-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
      &::after {
        content: '';
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    &:hover {
      .checkbox-input ~ .checkmark {
        background-color: #ccc;
      }
    }
    & .checkbox-input:checked ~ .checkmark {
      background-color: #f26e26;
      &::after {
        display: block;
      }
    }
  }
}

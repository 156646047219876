@import './_mixins.scss';
@import './_variables.scss';
@use 'sass:color';

* {
  box-sizing: border-box;
  outline: none;
}

html {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Sohne', 'Helvetica', 'Arial', sans-serif !important;
  font-size: $fontSizeMain;
  font-weight: 400;
  line-height: 1.5;
  overflow-y: auto;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  color: $mainTextColor;
  background-color: $mainBackgroundColor;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
button {
  font-family: 'Sohne', 'Helvetica', 'Arial', sans-serif !important;
  line-height: 1.5;
  margin: 0px;
  padding: 0px;
}

.customScrollBar {
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eee;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
  }
}

button,
a.button {
  border-radius: $radiusMain;
  background-color: $primaryColor;
  text-align: center;
  border: none;
  padding: 10px 20px;
  font-size: $fontSizeMain;
  font-weight: $fontWeightSemibold;
  color: $white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s linear;

  &.shadow {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  }

  &.outline {
    background-color: transparent;
    border: 1px solid $primaryColor;
    color: $primaryColor;
    // padding: 9px 14px;
  }

  &.round {
    border-radius: 30px;
  }

  &.button-large {
    font-size: $fontSizeLead;
    padding: 15px 22.5px;
  }

  &.button-dark-gray {
    background-color: $thirdTextColor;

    &:hover {
      background-color: scale-color($thirdTextColor, $lightness: -15%);
    }
  }

  &:hover {
    background-color: scale-color($primaryColor, $lightness: -15%);
  }

  &[disabled] {
    background-color: #dadada;
    color: #666666;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
      background-color: scale-color(#dadada, $lightness: -15%);
    }
  }
}

a.button {
  display: inline-block;
}

.app {
  width: 100%;
  background-color: $mainBackgroundColor;
}

.title {
  font-weight: 700;
}

.content-title {
  font-size: $fontSizeTitle;
  font-weight: 700;
}

.content-header-lg {
  font-size: $fontSizeHeaderLarge;
  font-weight: 700;
}

.content-header {
  font-size: $fontSizeHeader;
  font-weight: 700;
}

.text-title {
  font-size: $fontSizeTitle;
}

.text-header {
  font-size: $fontSizeHeader;
}

.text-lead {
  font-size: $fontSizeLead;
}

.text-main {
  font-size: $fontSizeMain;
}

.text-small {
  font-size: $fontSizeSmall;
}

.text-caption {
  font-size: $fontSizeCaption;
}

.font-strong {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

// .appContent {
//   vertical-align: top;
//   -webkit-overflow-scrolling: touch;
//   width: 100%;
//   margin: 0px auto;
//   max-width: $break-point-lg;
//   // padding: 0px 20px;
//   &.fullScreen {
//     max-width: initial;
//     padding: 0px;
//   }
// }

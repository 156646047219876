@import '../mixins.scss';
@import '../variables.scss';

.app.browse-page {
  // background-image: url(../../../public/images/browse/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;

  @media all and (max-width: $break-point-xs) {
    background-image: none;
  }
}

.browseView {
  width: 100%;
  margin-top: 30px;

  @media all and (max-width: $break-point-sm) {
    margin-top: 15px;
  }

  .header {
    line-height: 42px;
    color: #444443;
    margin-bottom: 16px;
    font-size: 48px;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: left;

    @media all and (max-width: $break-point-xs) {
      color: $secondaryTextColor;
    }
  }

  .sdgGoals {
    @include section-content;
  }

  .subheader-1 {
    font-size: 46px;
    line-height: 30px;
    font-style: normal;
    color: #444443;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .subheader {
    font-size: 24px;
    line-height: 30px;
    font-style: normal;
    color: #444443;
    font-weight: 400;
    margin-bottom: 20px;
  }

  section {
    // background: #fff;
    // box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    // border-radius: 3px;
    // padding: 35px 35px;
    @include section-content;
    margin-bottom: $spaceMain;
  }

  .other-stain-div {
    margin-top: 30px;
    a.button {
      // background-color: #999999;
      // border-radius: 8px;
      // font-style: normal;
      // font-weight: 700;
      // font-size: 16px;
      // // line-height: 19px;
      // text-align: right;

      & > svg {
        margin-left: 10px;
      }
    }
  }
  .all-sdg-div {
    margin-top: 30px;
    margin-bottom: 30px;
    a.button {
      // background-color: #999999;
      // border-radius: 8px;
      // font-style: normal;
      // font-weight: 700;
      // font-size: 16px;
      // // line-height: 19px;
      // text-align: right;

      & > svg {
        margin-left: 10px;
      }
    }
  }
  .banner {
    margin-top: $spaceMain;
    margin-bottom: $spaceMain;
    min-width: 728px;
    min-height: 90px;
    text-align: center;

    &.mobileBanner {
      min-width: 320px;
      min-height: 50px;
    }
  }

  .rec-pagination,
  .rec-arrow-left,
  .rec-arrow-right {
    display: none;
  }
  .button-div {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  .btn-prev-slider {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #444443;
    margin-right: auto;
  }
  .btn-next-slider {
    margin-left: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    color: #444443;
  }
  .activeUsers {
    .activeUsersContainer {
      margin: -14px;
      display: flex;

      @media all and (max-width: $break-point-xs) {
        margin-left: 0;
        margin-right: 0;
        flex-wrap: wrap;
      }

      .profileCard {
        cursor: pointer;
        transition: all 0.2s linear;
        &:hover {
          filter: brightness(97%);
        }
      }
    }

    .userPlaceholderContainer {
      display: flex;

      .userPlaceholderItem {
        position: relative;
        width: 25%;
      }
    }
  }

  .trendingPostsContainer {
    margin: -10px;
    display: flex;
    margin-top: 5%;

    @media all and (max-width: $break-point-xs) {
      margin-left: 0;
      margin-right: 0;
      flex-wrap: wrap;
    }

    .postContentPlaceholder {
      width: 33%;
      background: $white;
      margin-bottom: 20px;
    }

    .trendingPost {
      width: 100%;
      margin: 10px;

      @media all and (max-width: $break-point-xs) {
        width: 100%;
        flex-shrink: 0;
        margin: 10px 0;
      }

      .postCategory {
        font-size: 12px;
        color: $secondaryTextColor;
        border-bottom: 1px solid $lightBackgroundColor;
        padding-bottom: 7px;

        span {
          font-weight: 700;
          color: $primaryColor;
          cursor: pointer;
        }
      }

      .authorMeta {
        display: flex;
        margin: 10px 0 4px;

        .profileImage {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          flex-shrink: 0;
          cursor: pointer;

          &.noAvatar {
            background-color: $outlineColor;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              display: block;
              transform: translateX(-50%);
              width: 25px;
              height: 25px;
              background-color: #fff;
              mask: url(../../../public/images/icons/profile.svg) no-repeat
                center / contain;
            }
          }
        }

        .authorNameTime {
          width: 100%;

          .authorName {
            font-weight: bold;
            font-size: 12px;
            color: $secondaryTextColor;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            .subscribed {
              margin-left: 5px;
              height: 13px;
              width: 51px;
              background-image: url(../../../public/images/home/pro.png);
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }

          .postDate {
            font-size: 10px;
            color: $thirdTextColor;
          }
        }
      }

      .postComment {
        font-size: 14px;
        line-height: 20px;
        color: $secondaryTextColor;
        word-break: break-word;
        white-space: pre-line;
        white-space: nowrap;
        height: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .postDetails {
        border: 1px solid $lightBackgroundColor;
        border-radius: 3px;
        line-height: 18px;
        color: $thirdTextColor;
        margin-top: 10px;
        cursor: pointer;

        img {
          max-width: 100%;
          display: block;
          border-radius: 3px 3px 0 0;
        }

        .postTitle {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: $secondaryTextColor;
          margin: 10px 14px 0 14px;
        }

        .postSubtitle {
          font-size: 12px;
          margin: 10px 14px 0 14px;
          white-space: nowrap;
          height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .postLink {
          font-weight: bold;
          font-size: 10px;
          margin: 5px 14px 0 14px;
        }

        & > div:last-child {
          margin-bottom: 10px;
        }
      }

      .postMeta {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        line-height: 20px;
        color: $thirdTextColor;
        margin-top: 10px;

        .postCommentsCount {
          &:after {
            content: '';
            display: inline-block;
            width: 14.6px;
            height: 10px;
            background-color: $outlineColor;
            mask: url(../../../public/images/icons/comment.svg) no-repeat center /
              contain;
            margin-left: 6px;
          }
        }
      }
    }
  }

  .sdgGoalsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1px;

    @media all and (max-width: $break-point-xs) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .targetActivitiesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    margin-top: 30px;

    @media all and (max-width: $break-point-xs) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 15px;
    }
  }
}

@import '../mixins.scss';
@import '../variables.scss';

.app.existing-organization-page {
  background-image: url(../../../public/images/browse/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;

  @media all and (max-width: $break-point-xs) {
    background-image: none;
  }

  .appContent {
    background-color: transparent;
  }
  .labelText {
    font-weight: bold;
    margin: '10px 10px';
  }
}

.submitClaimBtn {
  width: 184px;
  height: 50;
  border-radius: 55px;
}

.profileClaim {
  display: flex;
  margin: 10px 0 4px;
  margin-bottom: 22px;

  .profileImage {
    width: 120px;
    height: 120px;
    margin-right: 10px;
    flex-shrink: 0;
    cursor: pointer;

    &.noAvatar {
      background-color: $outlineColor;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        transform: translateX(-50%);
        width: 100px;
        height: 100px;
        background-color: #fff;
        mask: url(../../../public/images/icons/profile.svg) no-repeat center /
          contain;
      }
    }
  }

  .authorNameTime {
    width: 100%;

    .authorName {
      margin-top: 30px;
      font-weight: 400;
      font-size: 42px;
      color: #333333;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      .subscribed {
        margin-left: 5px;
        height: 13px;
        width: 52px;
        background-image: url(../../../public/images/home/pro.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }

    .postDate {
      font-size: 10px;
      color: $thirdTextColor;
    }
  }
}
.registerForm {
  margin: 25px;
  .selectInput {
    margin: 10px 1px;
  }
  .textInput {
    margin: 10px 1px;
  }
  textarea {
    height: 300px;
  }
}
.wish_div {
  width: 354px;
}
.editprofile-exitsing-organization-hide {
  .browseView {
    margin-top: 10px;
  }
}

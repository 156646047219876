.milestone-container {
  display: flex;
  margin-left: 10px;
  flex-direction: row;
  align-items: stretch;
  .milestone-container-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    .dashed-line-vert {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      border: 2px dashed #f26e26;
      border-spacing: 10px;

      //   transform: rotate(-90deg);
    }
  }
  .milestone-container-right {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    padding-bottom: 15px;
    .role-label {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      /* identical to box height, or 167% */

      color: #666666;
    }
    .company-label {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      /* identical to box height, or 167% */

      color: #666666;
    }
  }
}
